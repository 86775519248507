import { Resolver as GraphCacheResolver, UpdateResolver as GraphCacheUpdateResolver, OptimisticMutationResolver as GraphCacheOptimisticMutationResolver, StorageAdapter as GraphCacheStorageAdapter } from '@urql/exchange-graphcache';
import { IntrospectionData } from '@urql/exchange-graphcache/dist/types/ast';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};

export type AllGames = {
  __typename: 'AllGames';
  games: Array<GameTile>;
  total: Scalars['Float'];
};

export type Button = {
  __typename: 'Button';
  label?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export type ContenfulAsset = {
  __typename: 'ContenfulAsset';
  height: Scalars['Float'];
  title: Scalars['String'];
  url: Scalars['String'];
  width: Scalars['Float'];
};

export type ContenfulAssetEntry = {
  __typename: 'ContenfulAssetEntry';
  fields?: Maybe<ContenfulAssetEntryFields>;
};

export type ContenfulAssetEntryFields = {
  __typename: 'ContenfulAssetEntryFields';
  description?: Maybe<Scalars['String']>;
  file?: Maybe<ContenfulAssetEntryFile>;
  title?: Maybe<Scalars['String']>;
};

export type ContenfulAssetEntryFile = {
  __typename: 'ContenfulAssetEntryFile';
  height: Scalars['Float'];
  url: Scalars['String'];
  width: Scalars['Float'];
};

export type CreateReviewInput = {
  gameSlug: Scalars['String'];
  rating: Scalars['Float'];
  review: Scalars['String'];
};

export type CreateReviewReplyInput = {
  id: Scalars['String'];
  text: Scalars['String'];
  type: Scalars['String'];
};

export type CreateScholarshipInput = {
  dateOfBirth: Scalars['String'];
  discordId: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  gameId: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  /** Ethereum address used for payouts of earnings */
  payoutWalletAddress?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
  walletCreator?: InputMaybe<WalletCreator>;
};

export type DescriptionJson = {
  __typename: 'DescriptionJSON';
  json?: Maybe<Scalars['JSONObject']>;
};

export type Earning = {
  __typename: 'Earning';
  metadata: Metadata;
  results: Array<Result>;
};

/** Game details */
export type Game = {
  __typename: 'Game';
  categories?: Maybe<Array<Scalars['String']>>;
  description: Scalars['String'];
  discordUrl: Scalars['String'];
  gameTags?: Maybe<Array<GameTag>>;
  hasInstallationGuide?: Maybe<Scalars['Boolean']>;
  hasScholarship?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  inGameImage?: Maybe<Media>;
  infoItems: Array<GameInfoItem>;
  isGameLive?: Maybe<Scalars['Boolean']>;
  isUpcoming: Scalars['Boolean'];
  linkedAcademyArticle?: Maybe<LinkedAcademyArticle>;
  linkedAcademyCategory?: Maybe<LinkedAcademyArticle>;
  linkedLaunchedButtonsComponent?: Maybe<LinkedLaunchedButtonsComponent>;
  linkedSeoTags?: Maybe<LinkedSeoComponent>;
  logo: Media;
  mainImage: Media;
  media: Array<Media>;
  metaDescription: Scalars['String'];
  metaTitle: Scalars['String'];
  notificationText?: Maybe<Scalars['String']>;
  platforms: Array<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  relatedGames: Array<Game>;
  /** Number of unhandled scholarship applications for the game */
  scholarshipApplicationCount: Scalars['Int'];
  slug: Scalars['String'];
  socialLinks?: Maybe<SocialLinks>;
  summary: Scalars['String'];
  sys?: Maybe<GameSys>;
  systemRequirements: Array<SystemRequirement>;
  title: Scalars['String'];
  tokens: Array<Scalars['String']>;
};

export type GameInfoItem = {
  __typename: 'GameInfoItem';
  option: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type GameSys = {
  __typename: 'GameSys';
  firstPublishedAt?: Maybe<Scalars['String']>;
};

export type GameTag = {
  __typename: 'GameTag';
  name: Scalars['String'];
};

export type GameTagItems = {
  __typename: 'GameTagItems';
  items: Array<GameTag>;
};

/** Game tile details */
export type GameTile = {
  __typename: 'GameTile';
  categories?: Maybe<Array<Scalars['String']>>;
  description: Scalars['String'];
  gameTagsCollection?: Maybe<GameTagItems>;
  id: Scalars['ID'];
  isUpcoming: Scalars['Boolean'];
  mainImage: Media;
  sys?: Maybe<GameSys>;
  title: Scalars['String'];
  urlSlug: Scalars['String'];
};

export type GetAllGamesInput = {
  blockchains?: InputMaybe<Array<Scalars['String']>>;
  categories?: InputMaybe<Array<Scalars['String']>>;
  freeToPlay?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Float']>;
  meritCircleGame?: InputMaybe<Scalars['Boolean']>;
  platforms?: InputMaybe<Array<Scalars['String']>>;
  searchTerm?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Array<Scalars['String']>>;
};

export type GetRepliesInput = {
  id: Scalars['String'];
  skip?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  type: Scalars['String'];
};

export type GetReviewsByGameInput = {
  gameSlug: Scalars['String'];
  skip?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<ReviewsOrderByInput>;
  take?: InputMaybe<Scalars['Float']>;
};

export type GetTaskQueryFilters = {
  id?: InputMaybe<Scalars['String']>;
  rules?: InputMaybe<TaskRulesInput>;
  slug?: InputMaybe<Scalars['String']>;
};

export type Giveaway = {
  __typename: 'Giveaway';
  description?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['DateTime']>;
  image?: Maybe<Scalars['String']>;
  millisecondsUntilEnd?: Maybe<Scalars['Float']>;
  minimumLevel: Scalars['Float'];
  order: Scalars['Float'];
  slug: Scalars['String'];
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<UserGiveawayStatus>;
  tasks?: Maybe<Array<LinkedTaskComponent>>;
  title: Scalars['String'];
  winners?: Maybe<Array<GiveawayWinners>>;
};

export type GiveawayOverview = {
  __typename: 'GiveawayOverview';
  groups?: Maybe<Array<GiveawayOverviewGroup>>;
  keywords?: Maybe<Scalars['String']>;
  linkedSeoTags?: Maybe<LinkedSeoComponent>;
  loggedUserSubtitle: SubtitleJson;
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  noFollow?: Maybe<Scalars['Boolean']>;
  noIndex?: Maybe<Scalars['Boolean']>;
  ogImage?: Maybe<ContenfulAsset>;
  subtitle: SubtitleJson;
  title: Scalars['String'];
};

export type GiveawayOverviewGroup = {
  __typename: 'GiveawayOverviewGroup';
  items?: Maybe<Array<Giveaway>>;
  minimumLevel: Scalars['Float'];
  xpRange?: Maybe<GiveawayXpRange>;
};

export type GiveawayWinners = {
  __typename: 'GiveawayWinners';
  name?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
};

export type GiveawayXpRange = {
  __typename: 'GiveawayXpRange';
  max?: Maybe<Scalars['Float']>;
  min: Scalars['Float'];
};

export type JoinGiveawayResponse = {
  __typename: 'JoinGiveawayResponse';
  success: Scalars['Boolean'];
  undoneTasks: Array<Task>;
};

/** User level */
export type Level = {
  __typename: 'Level';
  level: Scalars['Int'];
  max?: Maybe<Scalars['Int']>;
  min: Scalars['Int'];
};

export type LinkedAcademyArticle = {
  __typename: 'LinkedAcademyArticle';
  title: Scalars['String'];
  urlSlug: Scalars['String'];
};

export type LinkedLaunchedButtonsComponent = {
  __typename: 'LinkedLaunchedButtonsComponent';
  appStoreLink?: Maybe<Scalars['String']>;
  directPlayLink?: Maybe<Scalars['String']>;
  gameHomePageLink?: Maybe<Scalars['String']>;
  playStoreLink?: Maybe<Scalars['String']>;
};

export type LinkedSeoComponent = {
  __typename: 'LinkedSeoComponent';
  description?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  noFollow?: Maybe<Scalars['Boolean']>;
  noIndex?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

export type LinkedTaskComponent = {
  __typename: 'LinkedTaskComponent';
  description?: Maybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  form?: Maybe<Array<TaskFormField>>;
  href?: Maybe<Scalars['String']>;
  points: Scalars['Float'];
  rules: TaskRules;
  shouldPerform: Scalars['Boolean'];
  slug: Scalars['String'];
  status: TaskStatus;
  taskDescription?: Maybe<TaskDescriptionJson>;
  title: Scalars['String'];
};

export enum ListSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Media = {
  __typename: 'Media';
  height?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type Metadata = {
  __typename: 'Metadata';
  average?: Maybe<Scalars['Float']>;
  tokens: Array<Token>;
  total?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename: 'Mutation';
  /** Create reply for a review */
  createReply: ReviewReply;
  /** Creates review */
  createReview: Review;
  /** Create a scholarship for a game */
  createScholarship: Scholarship;
  /** Delete reply */
  deleteReply: ReviewReply;
  /** Deletes review */
  deleteReview: Review;
  /** Returns the result of joining a giveaway */
  joinGiveaway: JoinGiveawayResponse;
  /** uploads task with form data */
  performFormTask: Task;
  /** executes tasks */
  performTask: Array<Task>;
  sendReport: ReportResponse;
  /** Updates the current user */
  updateCurrentUser: User;
  /** Update user Discord username */
  updateDiscordUsername: SocialNameUpdateResult;
  /** Update the current users' password */
  updatePassword: User;
  /** Update user Twitter handle */
  updateTwitterHandle: SocialNameUpdateResult;
  /** Handles votes on review replies */
  voteOnReply: ReviewReply;
  /** Handles voting on review */
  voteOnReview: Review;
};


export type MutationCreateReplyArgs = {
  createReviewReply: CreateReviewReplyInput;
};


export type MutationCreateReviewArgs = {
  createReviewInput: CreateReviewInput;
};


export type MutationCreateScholarshipArgs = {
  input: CreateScholarshipInput;
};


export type MutationDeleteReplyArgs = {
  replyId: Scalars['String'];
};


export type MutationDeleteReviewArgs = {
  gameSlug: Scalars['String'];
  reviewId: Scalars['String'];
};


export type MutationJoinGiveawayArgs = {
  slug: Scalars['String'];
};


export type MutationPerformFormTaskArgs = {
  payload: TaskInputFormDto;
};


export type MutationPerformTaskArgs = {
  filters: GetTaskQueryFilters;
};


export type MutationSendReportArgs = {
  sendReportInput: SendReportInput;
};


export type MutationUpdateCurrentUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdatePasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationVoteOnReplyArgs = {
  voteOnReviewReplyInput: VoteOnReviewReplyInput;
};


export type MutationVoteOnReviewArgs = {
  voteOnReviewInput: VoteOnReviewInput;
};

export type Query = {
  __typename: 'Query';
  allGames: AllGames;
  /** Returns the current user */
  currentUser: User;
  /** Returns the current users level and level data */
  currentUserLevelData: Level;
  /** Returns earnings data */
  earnings: Earning;
  /** Returns a single game by its slug */
  game: Game;
  /** Get all replies for a review or a review reply */
  getReplies: ReviewsRepliesOverview;
  /** Returns all reviews for a game */
  getReviews: ReviewsOverview;
  /** Returns review summary for a game */
  getReviewsSummary: ReviewsByGameSummary;
  /** Returns a single task */
  getTask: Task;
  /** Returns giveaway data */
  giveaway: Giveaway;
  /** Returns giveaways overview */
  giveawayOverview: GiveawayOverview;
  /** Returns a game page or academy article page based on search term */
  searchContenful: Array<SearchResultItem>;
  /** Returns all time zones */
  timeZones: Array<Scalars['String']>;
  /** Returns user giveaway collection */
  userGiveawayCollection: Array<Giveaway>;
  /** Get the users' scholarship for a game */
  userScholarship: Scholarship;
  /** Get the users' open scholarship applications */
  userScholarships: Array<Scholarship>;
  /** Returns the timeline of the current user from algolia */
  userTimeline?: Maybe<Array<TimelineItem>>;
};


export type QueryAllGamesArgs = {
  getAllGamesInput: GetAllGamesInput;
};


export type QueryCurrentUserLevelDataArgs = {
  xp: Scalars['Float'];
};


export type QueryEarningsArgs = {
  input: QueryTypeInput;
};


export type QueryGameArgs = {
  slug: Scalars['String'];
};


export type QueryGetRepliesArgs = {
  getRepliesInput: GetRepliesInput;
};


export type QueryGetReviewsArgs = {
  getReviewsInput: GetReviewsByGameInput;
};


export type QueryGetReviewsSummaryArgs = {
  gameSlug: Scalars['String'];
};


export type QueryGetTaskArgs = {
  filters: GetTaskQueryFilters;
};


export type QueryGiveawayArgs = {
  slug: Scalars['String'];
};


export type QuerySearchContenfulArgs = {
  searchTerm: Scalars['String'];
};


export type QueryUserScholarshipArgs = {
  filter?: InputMaybe<ScholarshipFilterInput>;
  gameId: Scalars['String'];
};


export type QueryUserScholarshipsArgs = {
  filter?: InputMaybe<ScholarshipFilterInput>;
  sort?: InputMaybe<ScholarshipSortInput>;
};

export type QueryTypeInput = {
  type: Scalars['String'];
};

export type Quote = {
  __typename: 'Quote';
  USD: Scalars['Float'];
};

export type ReplyGrandParent = {
  __typename: 'ReplyGrandParent';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<ReplyType>;
};

export type ReplyParent = {
  __typename: 'ReplyParent';
  createdAt: Scalars['String'];
  id: Scalars['String'];
  parent?: Maybe<ReplyGrandParent>;
  rating?: Maybe<Scalars['Float']>;
  text: Scalars['String'];
  type: ReplyType;
  user: User;
};

export enum ReplyType {
  Reply = 'REPLY',
  Review = 'REVIEW'
}

export type ReportResponse = {
  __typename: 'ReportResponse';
  success: Scalars['Boolean'];
};

export type Result = {
  __typename: 'Result';
  date?: Maybe<Scalars['DateTime']>;
  tokens: Array<Token>;
};

export type Review = {
  __typename: 'Review';
  createdAt: Scalars['String'];
  downVotes?: Maybe<Scalars['Float']>;
  gameId: Scalars['String'];
  id: Scalars['String'];
  rating: Scalars['Float'];
  replies?: Maybe<Array<ReviewReply>>;
  text: Scalars['String'];
  upVotes?: Maybe<Scalars['Float']>;
  user: User;
  userVote?: Maybe<VoteType>;
};

export type ReviewReply = {
  __typename: 'ReviewReply';
  children?: Maybe<Array<ReviewReply>>;
  createdAt: Scalars['String'];
  downVotes?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  parent?: Maybe<ReviewReply>;
  review?: Maybe<Review>;
  text: Scalars['String'];
  upVotes?: Maybe<Scalars['Float']>;
  user: User;
  userVote?: Maybe<VoteType>;
};

export type ReviewSummary = {
  __typename: 'ReviewSummary';
  count?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

export type ReviewsByGameSummary = {
  __typename: 'ReviewsByGameSummary';
  averageRating: Scalars['Float'];
  count: Scalars['Float'];
  summary?: Maybe<Array<ReviewSummary>>;
  userReviewed: Scalars['Boolean'];
};

export type ReviewsOrderByInput = {
  createdAt?: InputMaybe<Sort>;
  rating?: InputMaybe<Sort>;
  voteDiff?: InputMaybe<Sort>;
};

export type ReviewsOverview = {
  __typename: 'ReviewsOverview';
  count: Scalars['Float'];
  reviews?: Maybe<Array<Review>>;
};

export type ReviewsRepliesOverview = {
  __typename: 'ReviewsRepliesOverview';
  parent?: Maybe<ReplyParent>;
  reviewReplies?: Maybe<Array<ReviewReply>>;
  total: Scalars['Float'];
};

/** Scholarship model including platform data */
export type Scholarship = {
  __typename: 'Scholarship';
  game: Game;
  id: Scalars['ID'];
  input: ScholarshipInput;
  queuePosition: Scalars['Int'];
  status: ScholarshipStatus;
  user: User;
};

export type ScholarshipFilterInput = {
  status?: InputMaybe<Array<ScholarshipStatus>>;
};

/** Input provided when creating the scholarship */
export type ScholarshipInput = {
  __typename: 'ScholarshipInput';
  dateOfBirth: Scalars['String'];
  discordId: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  gameId: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  /** Ethereum address used for payouts of earnings */
  payoutWalletAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  walletCreator?: Maybe<WalletCreator>;
};

export type ScholarshipSortInput = {
  createdAt?: InputMaybe<ListSortOrder>;
};

export enum ScholarshipStatus {
  Applied = 'APPLIED',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Terminated = 'TERMINATED'
}

export type SearchResultItem = {
  __typename: 'SearchResultItem';
  description: Scalars['String'];
  image?: Maybe<ContenfulAssetEntry>;
  pageType: Scalars['String'];
  title: Scalars['String'];
  urlSlug: Scalars['String'];
};

export type SendReportInput = {
  comment: Scalars['String'];
  id: Scalars['String'];
  reason: Scalars['String'];
  reportUrl: Scalars['String'];
  type: ReplyType;
};

export type SocialLinks = {
  __typename: 'SocialLinks';
  telegramLink?: Maybe<Scalars['String']>;
  twitterLink?: Maybe<Scalars['String']>;
  websiteLink?: Maybe<Scalars['String']>;
  youtubeLink?: Maybe<Scalars['String']>;
};

export type SocialNameUpdateResult = {
  __typename: 'SocialNameUpdateResult';
  success: Scalars['Boolean'];
};

export enum Sort {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SubtitleJson = {
  __typename: 'SubtitleJSON';
  json?: Maybe<Scalars['JSONObject']>;
};

export type SystemRequirement = {
  __typename: 'SystemRequirement';
  platform: Scalars['String'];
  requirements: Array<Scalars['String']>;
};

export type Task = {
  __typename: 'Task';
  points: Scalars['Float'];
  rules: TaskRules;
  slug: Scalars['String'];
  status: TaskStatus;
};

export type TaskDescriptionJson = {
  __typename: 'TaskDescriptionJSON';
  json?: Maybe<Scalars['JSONObject']>;
};

export type TaskFormField = {
  __typename: 'TaskFormField';
  fieldId?: Maybe<Scalars['String']>;
  fieldType?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
};

export type TaskInputFormDto = {
  formData?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export enum TaskRuleAction {
  DiscordConnectAccount = 'DISCORD_CONNECT_ACCOUNT',
  DiscordJoinGuild = 'DISCORD_JOIN_GUILD',
  FillOutForm = 'FILL_OUT_FORM',
  LevelUp = 'LEVEL_UP',
  Register = 'REGISTER',
  TwitterConnectAccount = 'TWITTER_CONNECT_ACCOUNT',
  TwitterFollowAccount = 'TWITTER_FOLLOW_ACCOUNT',
  Visit = 'VISIT'
}

export enum TaskRuleTimePeriod {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Monthly = 'MONTHLY',
  OneTime = 'ONE_TIME',
  Weekly = 'WEEKLY'
}

export type TaskRules = {
  __typename: 'TaskRules';
  action?: Maybe<TaskRuleAction>;
  timePeriod?: Maybe<TaskRuleTimePeriod>;
  value?: Maybe<Scalars['String']>;
};

export type TaskRulesInput = {
  action?: InputMaybe<TaskRuleAction>;
  timePeriod?: InputMaybe<TaskRuleTimePeriod>;
};

export enum TaskStatus {
  Done = 'DONE',
  Todo = 'TODO'
}

/** timeline model */
export type TimelineItem = {
  __typename: 'TimelineItem';
  attributes?: Maybe<Scalars['JSONObject']>;
  expireAt?: Maybe<Scalars['DateTime']>;
  startAt: Scalars['DateTime'];
  type?: Maybe<TimelineItemType>;
  user?: Maybe<User>;
};

export enum TimelineItemType {
  ArticleCard = 'ARTICLE_CARD',
  BroadcastCard = 'BROADCAST_CARD',
  GameCard = 'GAME_CARD',
  GiveawayCard = 'GIVEAWAY_CARD',
  LevelUpgrade = 'LEVEL_UPGRADE',
  MediumPostCard = 'MEDIUM_POST_CARD',
  ScholarshipCard = 'SCHOLARSHIP_CARD',
  TwitterCard = 'TWITTER_CARD'
}

export type Token = {
  __typename: 'Token';
  name: Scalars['String'];
  quote: Quote;
  value: Scalars['Float'];
};

export type UpdateUserInput = {
  dateOfBirth?: InputMaybe<Scalars['String']>;
  discordId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['Float']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  privateKey?: InputMaybe<Scalars['String']>;
  termsAgreed?: InputMaybe<Scalars['Boolean']>;
  timeZone?: InputMaybe<Scalars['String']>;
  twitterId?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  xp?: InputMaybe<Scalars['Float']>;
};

/** Complete user model including platform data */
export type User = {
  __typename: 'User';
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  discordId?: Maybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  level: Scalars['Int'];
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  requiredFieldsComplete?: Maybe<Scalars['Boolean']>;
  role: UserRole;
  /** External sub, generated by Auth0 */
  sub: Scalars['String'];
  termsAgreedAt?: Maybe<Scalars['DateTime']>;
  timeZone?: Maybe<Scalars['String']>;
  twitterId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  xp: Scalars['Int'];
};

export enum UserGiveawayStatus {
  Lost = 'LOST',
  NotParticipated = 'NOT_PARTICIPATED',
  Participated = 'PARTICIPATED',
  Won = 'WON'
}

export enum UserRole {
  Coach = 'COACH',
  Gamer = 'GAMER'
}

export type VoteOnReviewInput = {
  reviewId: Scalars['String'];
  voteType: VoteType;
};

export type VoteOnReviewReplyInput = {
  replyId: Scalars['String'];
  voteType: VoteType;
};

export enum VoteType {
  Downvote = 'DOWNVOTE',
  Upvote = 'UPVOTE'
}

export enum WalletCreator {
  IDontKnow = 'I_DONT_KNOW',
  Me = 'ME',
  SomeoneElse = 'SOMEONE_ELSE',
  WithFriend = 'WITH_FRIEND',
  WithOther = 'WITH_OTHER'
}

export type WithTypename<T extends { __typename?: any }> = T & { __typename: NonNullable<T['__typename']> };

export type GraphCacheKeysConfig = {
  AllGames?: (data: WithTypename<AllGames>) => null | string,
  Button?: (data: WithTypename<Button>) => null | string,
  ContenfulAsset?: (data: WithTypename<ContenfulAsset>) => null | string,
  ContenfulAssetEntry?: (data: WithTypename<ContenfulAssetEntry>) => null | string,
  ContenfulAssetEntryFields?: (data: WithTypename<ContenfulAssetEntryFields>) => null | string,
  ContenfulAssetEntryFile?: (data: WithTypename<ContenfulAssetEntryFile>) => null | string,
  DescriptionJSON?: (data: WithTypename<DescriptionJson>) => null | string,
  Earning?: (data: WithTypename<Earning>) => null | string,
  Game?: (data: WithTypename<Game>) => null | string,
  GameInfoItem?: (data: WithTypename<GameInfoItem>) => null | string,
  GameSys?: (data: WithTypename<GameSys>) => null | string,
  GameTag?: (data: WithTypename<GameTag>) => null | string,
  GameTagItems?: (data: WithTypename<GameTagItems>) => null | string,
  GameTile?: (data: WithTypename<GameTile>) => null | string,
  Giveaway?: (data: WithTypename<Giveaway>) => null | string,
  GiveawayOverview?: (data: WithTypename<GiveawayOverview>) => null | string,
  GiveawayOverviewGroup?: (data: WithTypename<GiveawayOverviewGroup>) => null | string,
  GiveawayWinners?: (data: WithTypename<GiveawayWinners>) => null | string,
  GiveawayXpRange?: (data: WithTypename<GiveawayXpRange>) => null | string,
  JoinGiveawayResponse?: (data: WithTypename<JoinGiveawayResponse>) => null | string,
  Level?: (data: WithTypename<Level>) => null | string,
  LinkedAcademyArticle?: (data: WithTypename<LinkedAcademyArticle>) => null | string,
  LinkedLaunchedButtonsComponent?: (data: WithTypename<LinkedLaunchedButtonsComponent>) => null | string,
  LinkedSeoComponent?: (data: WithTypename<LinkedSeoComponent>) => null | string,
  LinkedTaskComponent?: (data: WithTypename<LinkedTaskComponent>) => null | string,
  Media?: (data: WithTypename<Media>) => null | string,
  Metadata?: (data: WithTypename<Metadata>) => null | string,
  Quote?: (data: WithTypename<Quote>) => null | string,
  ReplyGrandParent?: (data: WithTypename<ReplyGrandParent>) => null | string,
  ReplyParent?: (data: WithTypename<ReplyParent>) => null | string,
  ReportResponse?: (data: WithTypename<ReportResponse>) => null | string,
  Result?: (data: WithTypename<Result>) => null | string,
  Review?: (data: WithTypename<Review>) => null | string,
  ReviewReply?: (data: WithTypename<ReviewReply>) => null | string,
  ReviewSummary?: (data: WithTypename<ReviewSummary>) => null | string,
  ReviewsByGameSummary?: (data: WithTypename<ReviewsByGameSummary>) => null | string,
  ReviewsOverview?: (data: WithTypename<ReviewsOverview>) => null | string,
  ReviewsRepliesOverview?: (data: WithTypename<ReviewsRepliesOverview>) => null | string,
  Scholarship?: (data: WithTypename<Scholarship>) => null | string,
  ScholarshipInput?: (data: WithTypename<ScholarshipInput>) => null | string,
  SearchResultItem?: (data: WithTypename<SearchResultItem>) => null | string,
  SocialLinks?: (data: WithTypename<SocialLinks>) => null | string,
  SocialNameUpdateResult?: (data: WithTypename<SocialNameUpdateResult>) => null | string,
  SubtitleJSON?: (data: WithTypename<SubtitleJson>) => null | string,
  SystemRequirement?: (data: WithTypename<SystemRequirement>) => null | string,
  Task?: (data: WithTypename<Task>) => null | string,
  TaskDescriptionJSON?: (data: WithTypename<TaskDescriptionJson>) => null | string,
  TaskFormField?: (data: WithTypename<TaskFormField>) => null | string,
  TaskRules?: (data: WithTypename<TaskRules>) => null | string,
  TimelineItem?: (data: WithTypename<TimelineItem>) => null | string,
  Token?: (data: WithTypename<Token>) => null | string,
  User?: (data: WithTypename<User>) => null | string
}

export type GraphCacheResolvers = {
  Query?: {
    currentUser?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, WithTypename<User> | string>,
    currentUserLevelData?: GraphCacheResolver<WithTypename<Query>, QueryCurrentUserLevelDataArgs, WithTypename<Level> | string>,
    timeZones?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<Scalars['String'] | string>>,
    game?: GraphCacheResolver<WithTypename<Query>, QueryGameArgs, WithTypename<Game> | string>,
    allGames?: GraphCacheResolver<WithTypename<Query>, QueryAllGamesArgs, WithTypename<AllGames> | string>,
    userScholarship?: GraphCacheResolver<WithTypename<Query>, QueryUserScholarshipArgs, WithTypename<Scholarship> | string>,
    userScholarships?: GraphCacheResolver<WithTypename<Query>, QueryUserScholarshipsArgs, Array<WithTypename<Scholarship> | string>>,
    earnings?: GraphCacheResolver<WithTypename<Query>, QueryEarningsArgs, WithTypename<Earning> | string>,
    userTimeline?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<TimelineItem> | string>>,
    giveawayOverview?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, WithTypename<GiveawayOverview> | string>,
    giveaway?: GraphCacheResolver<WithTypename<Query>, QueryGiveawayArgs, WithTypename<Giveaway> | string>,
    userGiveawayCollection?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<Giveaway> | string>>,
    getTask?: GraphCacheResolver<WithTypename<Query>, QueryGetTaskArgs, WithTypename<Task> | string>,
    getReviews?: GraphCacheResolver<WithTypename<Query>, QueryGetReviewsArgs, WithTypename<ReviewsOverview> | string>,
    getReviewsSummary?: GraphCacheResolver<WithTypename<Query>, QueryGetReviewsSummaryArgs, WithTypename<ReviewsByGameSummary> | string>,
    getReplies?: GraphCacheResolver<WithTypename<Query>, QueryGetRepliesArgs, WithTypename<ReviewsRepliesOverview> | string>,
    searchContenful?: GraphCacheResolver<WithTypename<Query>, QuerySearchContenfulArgs, Array<WithTypename<SearchResultItem> | string>>
  },
  AllGames?: {
    total?: GraphCacheResolver<WithTypename<AllGames>, Record<string, never>, Scalars['Float'] | string>,
    games?: GraphCacheResolver<WithTypename<AllGames>, Record<string, never>, Array<WithTypename<GameTile> | string>>
  },
  Button?: {
    label?: GraphCacheResolver<WithTypename<Button>, Record<string, never>, Scalars['String'] | string>,
    link?: GraphCacheResolver<WithTypename<Button>, Record<string, never>, Scalars['String'] | string>
  },
  ContenfulAsset?: {
    title?: GraphCacheResolver<WithTypename<ContenfulAsset>, Record<string, never>, Scalars['String'] | string>,
    width?: GraphCacheResolver<WithTypename<ContenfulAsset>, Record<string, never>, Scalars['Float'] | string>,
    height?: GraphCacheResolver<WithTypename<ContenfulAsset>, Record<string, never>, Scalars['Float'] | string>,
    url?: GraphCacheResolver<WithTypename<ContenfulAsset>, Record<string, never>, Scalars['String'] | string>
  },
  ContenfulAssetEntry?: {
    fields?: GraphCacheResolver<WithTypename<ContenfulAssetEntry>, Record<string, never>, WithTypename<ContenfulAssetEntryFields> | string>
  },
  ContenfulAssetEntryFields?: {
    title?: GraphCacheResolver<WithTypename<ContenfulAssetEntryFields>, Record<string, never>, Scalars['String'] | string>,
    description?: GraphCacheResolver<WithTypename<ContenfulAssetEntryFields>, Record<string, never>, Scalars['String'] | string>,
    file?: GraphCacheResolver<WithTypename<ContenfulAssetEntryFields>, Record<string, never>, WithTypename<ContenfulAssetEntryFile> | string>
  },
  ContenfulAssetEntryFile?: {
    url?: GraphCacheResolver<WithTypename<ContenfulAssetEntryFile>, Record<string, never>, Scalars['String'] | string>,
    width?: GraphCacheResolver<WithTypename<ContenfulAssetEntryFile>, Record<string, never>, Scalars['Float'] | string>,
    height?: GraphCacheResolver<WithTypename<ContenfulAssetEntryFile>, Record<string, never>, Scalars['Float'] | string>
  },
  DescriptionJSON?: {
    json?: GraphCacheResolver<WithTypename<DescriptionJson>, Record<string, never>, Scalars['JSONObject'] | string>
  },
  Earning?: {
    metadata?: GraphCacheResolver<WithTypename<Earning>, Record<string, never>, WithTypename<Metadata> | string>,
    results?: GraphCacheResolver<WithTypename<Earning>, Record<string, never>, Array<WithTypename<Result> | string>>
  },
  Game?: {
    id?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, Scalars['ID'] | string>,
    slug?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, Scalars['String'] | string>,
    title?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, Scalars['String'] | string>,
    summary?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, Scalars['String'] | string>,
    description?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, Scalars['String'] | string>,
    metaTitle?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, Scalars['String'] | string>,
    metaDescription?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, Scalars['String'] | string>,
    categories?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, Array<Scalars['String'] | string>>,
    platforms?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, Array<Scalars['String'] | string>>,
    tokens?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, Array<Scalars['String'] | string>>,
    logo?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, WithTypename<Media> | string>,
    mainImage?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, WithTypename<Media> | string>,
    media?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, Array<WithTypename<Media> | string>>,
    isUpcoming?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, Scalars['Boolean'] | string>,
    systemRequirements?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, Array<WithTypename<SystemRequirement> | string>>,
    discordUrl?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, Scalars['String'] | string>,
    relatedGames?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, Array<WithTypename<Game> | string>>,
    linkedAcademyCategory?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, WithTypename<LinkedAcademyArticle> | string>,
    hasInstallationGuide?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, Scalars['Boolean'] | string>,
    linkedAcademyArticle?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, WithTypename<LinkedAcademyArticle> | string>,
    linkedLaunchedButtonsComponent?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, WithTypename<LinkedLaunchedButtonsComponent> | string>,
    isGameLive?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, Scalars['Boolean'] | string>,
    hasScholarship?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, Scalars['Boolean'] | string>,
    notificationText?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, Scalars['String'] | string>,
    linkedSeoTags?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, WithTypename<LinkedSeoComponent> | string>,
    rating?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, Scalars['Float'] | string>,
    socialLinks?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, WithTypename<SocialLinks> | string>,
    infoItems?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, Array<WithTypename<GameInfoItem> | string>>,
    gameTags?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, Array<WithTypename<GameTag> | string>>,
    inGameImage?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, WithTypename<Media> | string>,
    sys?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, WithTypename<GameSys> | string>,
    scholarshipApplicationCount?: GraphCacheResolver<WithTypename<Game>, Record<string, never>, Scalars['Int'] | string>
  },
  GameInfoItem?: {
    option?: GraphCacheResolver<WithTypename<GameInfoItem>, Record<string, never>, Scalars['String'] | string>,
    values?: GraphCacheResolver<WithTypename<GameInfoItem>, Record<string, never>, Array<Scalars['String'] | string>>
  },
  GameSys?: {
    firstPublishedAt?: GraphCacheResolver<WithTypename<GameSys>, Record<string, never>, Scalars['String'] | string>
  },
  GameTag?: {
    name?: GraphCacheResolver<WithTypename<GameTag>, Record<string, never>, Scalars['String'] | string>
  },
  GameTagItems?: {
    items?: GraphCacheResolver<WithTypename<GameTagItems>, Record<string, never>, Array<WithTypename<GameTag> | string>>
  },
  GameTile?: {
    id?: GraphCacheResolver<WithTypename<GameTile>, Record<string, never>, Scalars['ID'] | string>,
    title?: GraphCacheResolver<WithTypename<GameTile>, Record<string, never>, Scalars['String'] | string>,
    description?: GraphCacheResolver<WithTypename<GameTile>, Record<string, never>, Scalars['String'] | string>,
    urlSlug?: GraphCacheResolver<WithTypename<GameTile>, Record<string, never>, Scalars['String'] | string>,
    isUpcoming?: GraphCacheResolver<WithTypename<GameTile>, Record<string, never>, Scalars['Boolean'] | string>,
    categories?: GraphCacheResolver<WithTypename<GameTile>, Record<string, never>, Array<Scalars['String'] | string>>,
    mainImage?: GraphCacheResolver<WithTypename<GameTile>, Record<string, never>, WithTypename<Media> | string>,
    sys?: GraphCacheResolver<WithTypename<GameTile>, Record<string, never>, WithTypename<GameSys> | string>,
    gameTagsCollection?: GraphCacheResolver<WithTypename<GameTile>, Record<string, never>, WithTypename<GameTagItems> | string>
  },
  Giveaway?: {
    minimumLevel?: GraphCacheResolver<WithTypename<Giveaway>, Record<string, never>, Scalars['Float'] | string>,
    order?: GraphCacheResolver<WithTypename<Giveaway>, Record<string, never>, Scalars['Float'] | string>,
    slug?: GraphCacheResolver<WithTypename<Giveaway>, Record<string, never>, Scalars['String'] | string>,
    tasks?: GraphCacheResolver<WithTypename<Giveaway>, Record<string, never>, Array<WithTypename<LinkedTaskComponent> | string>>,
    title?: GraphCacheResolver<WithTypename<Giveaway>, Record<string, never>, Scalars['String'] | string>,
    description?: GraphCacheResolver<WithTypename<Giveaway>, Record<string, never>, Scalars['String'] | string>,
    image?: GraphCacheResolver<WithTypename<Giveaway>, Record<string, never>, Scalars['String'] | string>,
    startAt?: GraphCacheResolver<WithTypename<Giveaway>, Record<string, never>, Scalars['DateTime'] | string>,
    endAt?: GraphCacheResolver<WithTypename<Giveaway>, Record<string, never>, Scalars['DateTime'] | string>,
    millisecondsUntilEnd?: GraphCacheResolver<WithTypename<Giveaway>, Record<string, never>, Scalars['Float'] | string>,
    status?: GraphCacheResolver<WithTypename<Giveaway>, Record<string, never>, UserGiveawayStatus | string>,
    winners?: GraphCacheResolver<WithTypename<Giveaway>, Record<string, never>, Array<WithTypename<GiveawayWinners> | string>>
  },
  GiveawayOverview?: {
    title?: GraphCacheResolver<WithTypename<GiveawayOverview>, Record<string, never>, Scalars['String'] | string>,
    metaTitle?: GraphCacheResolver<WithTypename<GiveawayOverview>, Record<string, never>, Scalars['String'] | string>,
    metaDescription?: GraphCacheResolver<WithTypename<GiveawayOverview>, Record<string, never>, Scalars['String'] | string>,
    noFollow?: GraphCacheResolver<WithTypename<GiveawayOverview>, Record<string, never>, Scalars['Boolean'] | string>,
    noIndex?: GraphCacheResolver<WithTypename<GiveawayOverview>, Record<string, never>, Scalars['Boolean'] | string>,
    ogImage?: GraphCacheResolver<WithTypename<GiveawayOverview>, Record<string, never>, WithTypename<ContenfulAsset> | string>,
    keywords?: GraphCacheResolver<WithTypename<GiveawayOverview>, Record<string, never>, Scalars['String'] | string>,
    subtitle?: GraphCacheResolver<WithTypename<GiveawayOverview>, Record<string, never>, WithTypename<SubtitleJson> | string>,
    loggedUserSubtitle?: GraphCacheResolver<WithTypename<GiveawayOverview>, Record<string, never>, WithTypename<SubtitleJson> | string>,
    groups?: GraphCacheResolver<WithTypename<GiveawayOverview>, Record<string, never>, Array<WithTypename<GiveawayOverviewGroup> | string>>,
    linkedSeoTags?: GraphCacheResolver<WithTypename<GiveawayOverview>, Record<string, never>, WithTypename<LinkedSeoComponent> | string>
  },
  GiveawayOverviewGroup?: {
    minimumLevel?: GraphCacheResolver<WithTypename<GiveawayOverviewGroup>, Record<string, never>, Scalars['Float'] | string>,
    xpRange?: GraphCacheResolver<WithTypename<GiveawayOverviewGroup>, Record<string, never>, WithTypename<GiveawayXpRange> | string>,
    items?: GraphCacheResolver<WithTypename<GiveawayOverviewGroup>, Record<string, never>, Array<WithTypename<Giveaway> | string>>
  },
  GiveawayWinners?: {
    profilePicture?: GraphCacheResolver<WithTypename<GiveawayWinners>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<GiveawayWinners>, Record<string, never>, Scalars['String'] | string>
  },
  GiveawayXpRange?: {
    min?: GraphCacheResolver<WithTypename<GiveawayXpRange>, Record<string, never>, Scalars['Float'] | string>,
    max?: GraphCacheResolver<WithTypename<GiveawayXpRange>, Record<string, never>, Scalars['Float'] | string>
  },
  JoinGiveawayResponse?: {
    success?: GraphCacheResolver<WithTypename<JoinGiveawayResponse>, Record<string, never>, Scalars['Boolean'] | string>,
    undoneTasks?: GraphCacheResolver<WithTypename<JoinGiveawayResponse>, Record<string, never>, Array<WithTypename<Task> | string>>
  },
  Level?: {
    min?: GraphCacheResolver<WithTypename<Level>, Record<string, never>, Scalars['Int'] | string>,
    max?: GraphCacheResolver<WithTypename<Level>, Record<string, never>, Scalars['Int'] | string>,
    level?: GraphCacheResolver<WithTypename<Level>, Record<string, never>, Scalars['Int'] | string>
  },
  LinkedAcademyArticle?: {
    title?: GraphCacheResolver<WithTypename<LinkedAcademyArticle>, Record<string, never>, Scalars['String'] | string>,
    urlSlug?: GraphCacheResolver<WithTypename<LinkedAcademyArticle>, Record<string, never>, Scalars['String'] | string>
  },
  LinkedLaunchedButtonsComponent?: {
    playStoreLink?: GraphCacheResolver<WithTypename<LinkedLaunchedButtonsComponent>, Record<string, never>, Scalars['String'] | string>,
    appStoreLink?: GraphCacheResolver<WithTypename<LinkedLaunchedButtonsComponent>, Record<string, never>, Scalars['String'] | string>,
    directPlayLink?: GraphCacheResolver<WithTypename<LinkedLaunchedButtonsComponent>, Record<string, never>, Scalars['String'] | string>,
    gameHomePageLink?: GraphCacheResolver<WithTypename<LinkedLaunchedButtonsComponent>, Record<string, never>, Scalars['String'] | string>
  },
  LinkedSeoComponent?: {
    title?: GraphCacheResolver<WithTypename<LinkedSeoComponent>, Record<string, never>, Scalars['String'] | string>,
    description?: GraphCacheResolver<WithTypename<LinkedSeoComponent>, Record<string, never>, Scalars['String'] | string>,
    keywords?: GraphCacheResolver<WithTypename<LinkedSeoComponent>, Record<string, never>, Scalars['String'] | string>,
    noIndex?: GraphCacheResolver<WithTypename<LinkedSeoComponent>, Record<string, never>, Scalars['Boolean'] | string>,
    noFollow?: GraphCacheResolver<WithTypename<LinkedSeoComponent>, Record<string, never>, Scalars['Boolean'] | string>
  },
  LinkedTaskComponent?: {
    slug?: GraphCacheResolver<WithTypename<LinkedTaskComponent>, Record<string, never>, Scalars['String'] | string>,
    points?: GraphCacheResolver<WithTypename<LinkedTaskComponent>, Record<string, never>, Scalars['Float'] | string>,
    rules?: GraphCacheResolver<WithTypename<LinkedTaskComponent>, Record<string, never>, WithTypename<TaskRules> | string>,
    status?: GraphCacheResolver<WithTypename<LinkedTaskComponent>, Record<string, never>, TaskStatus | string>,
    title?: GraphCacheResolver<WithTypename<LinkedTaskComponent>, Record<string, never>, Scalars['String'] | string>,
    description?: GraphCacheResolver<WithTypename<LinkedTaskComponent>, Record<string, never>, Scalars['String'] | string>,
    taskDescription?: GraphCacheResolver<WithTypename<LinkedTaskComponent>, Record<string, never>, WithTypename<TaskDescriptionJson> | string>,
    href?: GraphCacheResolver<WithTypename<LinkedTaskComponent>, Record<string, never>, Scalars['String'] | string>,
    form?: GraphCacheResolver<WithTypename<LinkedTaskComponent>, Record<string, never>, Array<WithTypename<TaskFormField> | string>>,
    disabled?: GraphCacheResolver<WithTypename<LinkedTaskComponent>, Record<string, never>, Scalars['Boolean'] | string>,
    shouldPerform?: GraphCacheResolver<WithTypename<LinkedTaskComponent>, Record<string, never>, Scalars['Boolean'] | string>
  },
  Media?: {
    title?: GraphCacheResolver<WithTypename<Media>, Record<string, never>, Scalars['String'] | string>,
    url?: GraphCacheResolver<WithTypename<Media>, Record<string, never>, Scalars['String'] | string>,
    width?: GraphCacheResolver<WithTypename<Media>, Record<string, never>, Scalars['Float'] | string>,
    height?: GraphCacheResolver<WithTypename<Media>, Record<string, never>, Scalars['Float'] | string>
  },
  Metadata?: {
    total?: GraphCacheResolver<WithTypename<Metadata>, Record<string, never>, Scalars['Float'] | string>,
    average?: GraphCacheResolver<WithTypename<Metadata>, Record<string, never>, Scalars['Float'] | string>,
    tokens?: GraphCacheResolver<WithTypename<Metadata>, Record<string, never>, Array<WithTypename<Token> | string>>
  },
  Quote?: {
    USD?: GraphCacheResolver<WithTypename<Quote>, Record<string, never>, Scalars['Float'] | string>
  },
  ReplyGrandParent?: {
    id?: GraphCacheResolver<WithTypename<ReplyGrandParent>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<ReplyGrandParent>, Record<string, never>, ReplyType | string>
  },
  ReplyParent?: {
    id?: GraphCacheResolver<WithTypename<ReplyParent>, Record<string, never>, Scalars['String'] | string>,
    text?: GraphCacheResolver<WithTypename<ReplyParent>, Record<string, never>, Scalars['String'] | string>,
    user?: GraphCacheResolver<WithTypename<ReplyParent>, Record<string, never>, WithTypename<User> | string>,
    createdAt?: GraphCacheResolver<WithTypename<ReplyParent>, Record<string, never>, Scalars['String'] | string>,
    rating?: GraphCacheResolver<WithTypename<ReplyParent>, Record<string, never>, Scalars['Float'] | string>,
    type?: GraphCacheResolver<WithTypename<ReplyParent>, Record<string, never>, ReplyType | string>,
    parent?: GraphCacheResolver<WithTypename<ReplyParent>, Record<string, never>, WithTypename<ReplyGrandParent> | string>
  },
  ReportResponse?: {
    success?: GraphCacheResolver<WithTypename<ReportResponse>, Record<string, never>, Scalars['Boolean'] | string>
  },
  Result?: {
    date?: GraphCacheResolver<WithTypename<Result>, Record<string, never>, Scalars['DateTime'] | string>,
    tokens?: GraphCacheResolver<WithTypename<Result>, Record<string, never>, Array<WithTypename<Token> | string>>
  },
  Review?: {
    id?: GraphCacheResolver<WithTypename<Review>, Record<string, never>, Scalars['String'] | string>,
    text?: GraphCacheResolver<WithTypename<Review>, Record<string, never>, Scalars['String'] | string>,
    rating?: GraphCacheResolver<WithTypename<Review>, Record<string, never>, Scalars['Float'] | string>,
    user?: GraphCacheResolver<WithTypename<Review>, Record<string, never>, WithTypename<User> | string>,
    gameId?: GraphCacheResolver<WithTypename<Review>, Record<string, never>, Scalars['String'] | string>,
    createdAt?: GraphCacheResolver<WithTypename<Review>, Record<string, never>, Scalars['String'] | string>,
    replies?: GraphCacheResolver<WithTypename<Review>, Record<string, never>, Array<WithTypename<ReviewReply> | string>>,
    downVotes?: GraphCacheResolver<WithTypename<Review>, Record<string, never>, Scalars['Float'] | string>,
    upVotes?: GraphCacheResolver<WithTypename<Review>, Record<string, never>, Scalars['Float'] | string>,
    userVote?: GraphCacheResolver<WithTypename<Review>, Record<string, never>, VoteType | string>
  },
  ReviewReply?: {
    id?: GraphCacheResolver<WithTypename<ReviewReply>, Record<string, never>, Scalars['String'] | string>,
    text?: GraphCacheResolver<WithTypename<ReviewReply>, Record<string, never>, Scalars['String'] | string>,
    downVotes?: GraphCacheResolver<WithTypename<ReviewReply>, Record<string, never>, Scalars['Float'] | string>,
    upVotes?: GraphCacheResolver<WithTypename<ReviewReply>, Record<string, never>, Scalars['Float'] | string>,
    userVote?: GraphCacheResolver<WithTypename<ReviewReply>, Record<string, never>, VoteType | string>,
    createdAt?: GraphCacheResolver<WithTypename<ReviewReply>, Record<string, never>, Scalars['String'] | string>,
    user?: GraphCacheResolver<WithTypename<ReviewReply>, Record<string, never>, WithTypename<User> | string>,
    review?: GraphCacheResolver<WithTypename<ReviewReply>, Record<string, never>, WithTypename<Review> | string>,
    parent?: GraphCacheResolver<WithTypename<ReviewReply>, Record<string, never>, WithTypename<ReviewReply> | string>,
    children?: GraphCacheResolver<WithTypename<ReviewReply>, Record<string, never>, Array<WithTypename<ReviewReply> | string>>
  },
  ReviewSummary?: {
    rating?: GraphCacheResolver<WithTypename<ReviewSummary>, Record<string, never>, Scalars['Float'] | string>,
    count?: GraphCacheResolver<WithTypename<ReviewSummary>, Record<string, never>, Scalars['Float'] | string>
  },
  ReviewsByGameSummary?: {
    summary?: GraphCacheResolver<WithTypename<ReviewsByGameSummary>, Record<string, never>, Array<WithTypename<ReviewSummary> | string>>,
    averageRating?: GraphCacheResolver<WithTypename<ReviewsByGameSummary>, Record<string, never>, Scalars['Float'] | string>,
    count?: GraphCacheResolver<WithTypename<ReviewsByGameSummary>, Record<string, never>, Scalars['Float'] | string>,
    userReviewed?: GraphCacheResolver<WithTypename<ReviewsByGameSummary>, Record<string, never>, Scalars['Boolean'] | string>
  },
  ReviewsOverview?: {
    reviews?: GraphCacheResolver<WithTypename<ReviewsOverview>, Record<string, never>, Array<WithTypename<Review> | string>>,
    count?: GraphCacheResolver<WithTypename<ReviewsOverview>, Record<string, never>, Scalars['Float'] | string>
  },
  ReviewsRepliesOverview?: {
    reviewReplies?: GraphCacheResolver<WithTypename<ReviewsRepliesOverview>, Record<string, never>, Array<WithTypename<ReviewReply> | string>>,
    total?: GraphCacheResolver<WithTypename<ReviewsRepliesOverview>, Record<string, never>, Scalars['Float'] | string>,
    parent?: GraphCacheResolver<WithTypename<ReviewsRepliesOverview>, Record<string, never>, WithTypename<ReplyParent> | string>
  },
  Scholarship?: {
    id?: GraphCacheResolver<WithTypename<Scholarship>, Record<string, never>, Scalars['ID'] | string>,
    user?: GraphCacheResolver<WithTypename<Scholarship>, Record<string, never>, WithTypename<User> | string>,
    status?: GraphCacheResolver<WithTypename<Scholarship>, Record<string, never>, ScholarshipStatus | string>,
    input?: GraphCacheResolver<WithTypename<Scholarship>, Record<string, never>, WithTypename<ScholarshipInput> | string>,
    queuePosition?: GraphCacheResolver<WithTypename<Scholarship>, Record<string, never>, Scalars['Int'] | string>,
    game?: GraphCacheResolver<WithTypename<Scholarship>, Record<string, never>, WithTypename<Game> | string>
  },
  ScholarshipInput?: {
    gameId?: GraphCacheResolver<WithTypename<ScholarshipInput>, Record<string, never>, Scalars['String'] | string>,
    discordId?: GraphCacheResolver<WithTypename<ScholarshipInput>, Record<string, never>, Scalars['String'] | string>,
    dateOfBirth?: GraphCacheResolver<WithTypename<ScholarshipInput>, Record<string, never>, Scalars['String'] | string>,
    firstName?: GraphCacheResolver<WithTypename<ScholarshipInput>, Record<string, never>, Scalars['String'] | string>,
    lastName?: GraphCacheResolver<WithTypename<ScholarshipInput>, Record<string, never>, Scalars['String'] | string>,
    phoneNumber?: GraphCacheResolver<WithTypename<ScholarshipInput>, Record<string, never>, Scalars['String'] | string>,
    timeZone?: GraphCacheResolver<WithTypename<ScholarshipInput>, Record<string, never>, Scalars['String'] | string>,
    payoutWalletAddress?: GraphCacheResolver<WithTypename<ScholarshipInput>, Record<string, never>, Scalars['String'] | string>,
    walletCreator?: GraphCacheResolver<WithTypename<ScholarshipInput>, Record<string, never>, WalletCreator | string>
  },
  SearchResultItem?: {
    pageType?: GraphCacheResolver<WithTypename<SearchResultItem>, Record<string, never>, Scalars['String'] | string>,
    title?: GraphCacheResolver<WithTypename<SearchResultItem>, Record<string, never>, Scalars['String'] | string>,
    description?: GraphCacheResolver<WithTypename<SearchResultItem>, Record<string, never>, Scalars['String'] | string>,
    image?: GraphCacheResolver<WithTypename<SearchResultItem>, Record<string, never>, WithTypename<ContenfulAssetEntry> | string>,
    urlSlug?: GraphCacheResolver<WithTypename<SearchResultItem>, Record<string, never>, Scalars['String'] | string>
  },
  SocialLinks?: {
    websiteLink?: GraphCacheResolver<WithTypename<SocialLinks>, Record<string, never>, Scalars['String'] | string>,
    twitterLink?: GraphCacheResolver<WithTypename<SocialLinks>, Record<string, never>, Scalars['String'] | string>,
    telegramLink?: GraphCacheResolver<WithTypename<SocialLinks>, Record<string, never>, Scalars['String'] | string>,
    youtubeLink?: GraphCacheResolver<WithTypename<SocialLinks>, Record<string, never>, Scalars['String'] | string>
  },
  SocialNameUpdateResult?: {
    success?: GraphCacheResolver<WithTypename<SocialNameUpdateResult>, Record<string, never>, Scalars['Boolean'] | string>
  },
  SubtitleJSON?: {
    json?: GraphCacheResolver<WithTypename<SubtitleJson>, Record<string, never>, Scalars['JSONObject'] | string>
  },
  SystemRequirement?: {
    platform?: GraphCacheResolver<WithTypename<SystemRequirement>, Record<string, never>, Scalars['String'] | string>,
    requirements?: GraphCacheResolver<WithTypename<SystemRequirement>, Record<string, never>, Array<Scalars['String'] | string>>
  },
  Task?: {
    slug?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['String'] | string>,
    points?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['Float'] | string>,
    rules?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, WithTypename<TaskRules> | string>,
    status?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, TaskStatus | string>
  },
  TaskDescriptionJSON?: {
    json?: GraphCacheResolver<WithTypename<TaskDescriptionJson>, Record<string, never>, Scalars['JSONObject'] | string>
  },
  TaskFormField?: {
    fieldId?: GraphCacheResolver<WithTypename<TaskFormField>, Record<string, never>, Scalars['String'] | string>,
    question?: GraphCacheResolver<WithTypename<TaskFormField>, Record<string, never>, Scalars['String'] | string>,
    fieldType?: GraphCacheResolver<WithTypename<TaskFormField>, Record<string, never>, Scalars['String'] | string>
  },
  TaskRules?: {
    timePeriod?: GraphCacheResolver<WithTypename<TaskRules>, Record<string, never>, TaskRuleTimePeriod | string>,
    action?: GraphCacheResolver<WithTypename<TaskRules>, Record<string, never>, TaskRuleAction | string>,
    value?: GraphCacheResolver<WithTypename<TaskRules>, Record<string, never>, Scalars['String'] | string>
  },
  TimelineItem?: {
    user?: GraphCacheResolver<WithTypename<TimelineItem>, Record<string, never>, WithTypename<User> | string>,
    attributes?: GraphCacheResolver<WithTypename<TimelineItem>, Record<string, never>, Scalars['JSONObject'] | string>,
    type?: GraphCacheResolver<WithTypename<TimelineItem>, Record<string, never>, TimelineItemType | string>,
    startAt?: GraphCacheResolver<WithTypename<TimelineItem>, Record<string, never>, Scalars['DateTime'] | string>,
    expireAt?: GraphCacheResolver<WithTypename<TimelineItem>, Record<string, never>, Scalars['DateTime'] | string>
  },
  Token?: {
    name?: GraphCacheResolver<WithTypename<Token>, Record<string, never>, Scalars['String'] | string>,
    value?: GraphCacheResolver<WithTypename<Token>, Record<string, never>, Scalars['Float'] | string>,
    quote?: GraphCacheResolver<WithTypename<Token>, Record<string, never>, WithTypename<Quote> | string>
  },
  User?: {
    sub?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    emailAddress?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    role?: GraphCacheResolver<WithTypename<User>, Record<string, never>, UserRole | string>,
    profilePicture?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    username?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    firstName?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    lastName?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['ID'] | string>,
    discordId?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    twitterId?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    dateOfBirth?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    timeZone?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    phoneNumber?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    createdAt?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['DateTime'] | string>,
    termsAgreedAt?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['DateTime'] | string>,
    xp?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['Int'] | string>,
    level?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['Int'] | string>,
    profilePictureUrl?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    requiredFieldsComplete?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['Boolean'] | string>
  }
};

export type GraphCacheOptimisticUpdaters = {
  updateCurrentUser?: GraphCacheOptimisticMutationResolver<MutationUpdateCurrentUserArgs, WithTypename<User>>,
  updatePassword?: GraphCacheOptimisticMutationResolver<MutationUpdatePasswordArgs, WithTypename<User>>,
  updateTwitterHandle?: GraphCacheOptimisticMutationResolver<Record<string, never>, WithTypename<SocialNameUpdateResult>>,
  updateDiscordUsername?: GraphCacheOptimisticMutationResolver<Record<string, never>, WithTypename<SocialNameUpdateResult>>,
  createScholarship?: GraphCacheOptimisticMutationResolver<MutationCreateScholarshipArgs, WithTypename<Scholarship>>,
  performTask?: GraphCacheOptimisticMutationResolver<MutationPerformTaskArgs, Array<WithTypename<Task>>>,
  performFormTask?: GraphCacheOptimisticMutationResolver<MutationPerformFormTaskArgs, WithTypename<Task>>,
  joinGiveaway?: GraphCacheOptimisticMutationResolver<MutationJoinGiveawayArgs, WithTypename<JoinGiveawayResponse>>,
  createReview?: GraphCacheOptimisticMutationResolver<MutationCreateReviewArgs, WithTypename<Review>>,
  deleteReview?: GraphCacheOptimisticMutationResolver<MutationDeleteReviewArgs, WithTypename<Review>>,
  voteOnReview?: GraphCacheOptimisticMutationResolver<MutationVoteOnReviewArgs, WithTypename<Review>>,
  createReply?: GraphCacheOptimisticMutationResolver<MutationCreateReplyArgs, WithTypename<ReviewReply>>,
  deleteReply?: GraphCacheOptimisticMutationResolver<MutationDeleteReplyArgs, WithTypename<ReviewReply>>,
  voteOnReply?: GraphCacheOptimisticMutationResolver<MutationVoteOnReplyArgs, WithTypename<ReviewReply>>,
  sendReport?: GraphCacheOptimisticMutationResolver<MutationSendReportArgs, WithTypename<ReportResponse>>
};

export type GraphCacheUpdaters = {
  Mutation?: {
    updateCurrentUser?: GraphCacheUpdateResolver<{ updateCurrentUser: WithTypename<User> }, MutationUpdateCurrentUserArgs>,
    updatePassword?: GraphCacheUpdateResolver<{ updatePassword: WithTypename<User> }, MutationUpdatePasswordArgs>,
    updateTwitterHandle?: GraphCacheUpdateResolver<{ updateTwitterHandle: WithTypename<SocialNameUpdateResult> }, Record<string, never>>,
    updateDiscordUsername?: GraphCacheUpdateResolver<{ updateDiscordUsername: WithTypename<SocialNameUpdateResult> }, Record<string, never>>,
    createScholarship?: GraphCacheUpdateResolver<{ createScholarship: WithTypename<Scholarship> }, MutationCreateScholarshipArgs>,
    performTask?: GraphCacheUpdateResolver<{ performTask: Array<WithTypename<Task>> }, MutationPerformTaskArgs>,
    performFormTask?: GraphCacheUpdateResolver<{ performFormTask: WithTypename<Task> }, MutationPerformFormTaskArgs>,
    joinGiveaway?: GraphCacheUpdateResolver<{ joinGiveaway: WithTypename<JoinGiveawayResponse> }, MutationJoinGiveawayArgs>,
    createReview?: GraphCacheUpdateResolver<{ createReview: WithTypename<Review> }, MutationCreateReviewArgs>,
    deleteReview?: GraphCacheUpdateResolver<{ deleteReview: WithTypename<Review> }, MutationDeleteReviewArgs>,
    voteOnReview?: GraphCacheUpdateResolver<{ voteOnReview: WithTypename<Review> }, MutationVoteOnReviewArgs>,
    createReply?: GraphCacheUpdateResolver<{ createReply: WithTypename<ReviewReply> }, MutationCreateReplyArgs>,
    deleteReply?: GraphCacheUpdateResolver<{ deleteReply: WithTypename<ReviewReply> }, MutationDeleteReplyArgs>,
    voteOnReply?: GraphCacheUpdateResolver<{ voteOnReply: WithTypename<ReviewReply> }, MutationVoteOnReplyArgs>,
    sendReport?: GraphCacheUpdateResolver<{ sendReport: WithTypename<ReportResponse> }, MutationSendReportArgs>
  },
  Subscription?: {},
};

export type GraphCacheConfig = {
  schema?: IntrospectionData,
  updates?: GraphCacheUpdaters,
  keys?: GraphCacheKeysConfig,
  optimistic?: GraphCacheOptimisticUpdaters,
  resolvers?: GraphCacheResolvers,
  storage?: GraphCacheStorageAdapter
};