
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import '@meritcircle/ui/theme/fonts.css';
import '../styles/cookiebot.css';
import { useViewportHeightVar } from '@meritcircle/ui';
import { isBrowser } from '@/utils/isBrowser';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from '@meritcircle/ui/theme';
import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/dist/shared/lib/router/router';
import React, { useEffect } from 'react';
import ErrorBoundary from '../components/error-boundary';
import { MetaDataProvider } from '@/components/contexts/meta-data/meta-data.provider';
import CurrentUserProvider from '@/components/contexts/current-user/current-user.provider';
import Snippets from '@/components/utils/snippets/snippets';
import { useRouter } from 'next/router';
import { useRedirect } from '@/utils/hooks';
import ToastsProvider from '@/components/contexts/toasts/toasts.provider';
import ReviewsPaginationProvider from '@/components/contexts/reviews-pagination/reviews-pagination.provider';
if (isBrowser) {
    import('@/utils/detectTouch');
    import('@/utils/detectKeyboardFocus');
}
const MeritApp = ({ Component: PageComponent, pageProps }: AppProps) => {
    useViewportHeightVar();
    const location = useRouter();
    const { checkRedirection } = useRedirect();
    useEffect(() => {
        checkRedirection(location);
    }, [checkRedirection, location]);
    return (<>
      <Snippets />
      <DefaultSeo titleTemplate={`%s | Beam Hub`}/>
      <ChakraProvider theme={theme} resetCSS>
        <ToastsProvider>
          <CurrentUserProvider {...pageProps}>
            <ReviewsPaginationProvider>
              <MetaDataProvider location={location} market={{ language: 'en', country: 'US', timeZone: 'Europe/Amsterdam' }}>
                <ErrorBoundary>
                  <PageComponent {...pageProps}/>
                </ErrorBoundary>
              </MetaDataProvider>
            </ReviewsPaginationProvider>
          </CurrentUserProvider>
        </ToastsProvider>
      </ChakraProvider>
    </>);
};
const __Next_Translate__Page__19123446358__ = MeritApp;

    export default __appWithI18n(__Next_Translate__Page__19123446358__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  