module.exports = {
  defaultLocale: 'en',
  locales: ['en'],
  pages: {
    '*': ['common', 'errors'],
    '/': ['dashboard', 'games', 'timeline'],
    '/verify-email': ['verify-email'],
    'rgx:/onboarding(.*)': ['onboarding', 'profile'],
    'rgx:/profile(.*)': ['profile'],
    'rgx:/games(.*)': ['games', 'profile', 'reviews'],
    'rgx:/academy(.*)': ['academy'],
    'rgx:/leaderboards(.*)': ['leaderboards'],
    'rgx:/earnings(.*)': ['earnings'],
    'rgx:/search(.*)': ['search', 'games', 'academy', 'leaderboards'],
    'rgx:/rewards(.*)': ['rewards'],
    'rgx:/gaming-guides(.*)': ['gaming-guides'],
  },
  logBuild: process.env.NODE_ENV !== 'production',
  loadLocaleFrom: (lang, ns) => import(`./public/locales/${lang}/${ns}.json`).then(m => m.default),
};
