import { useUserScholarshipsQuery } from '@/client/graphql/scholarships.generated';
import {
  ScholarshipStatus,
  TaskRuleAction,
  TaskRuleTimePeriod,
} from '@/client/graphql/types.generated';
import {
  useCurrentUserQuery,
  UserLevelDocument,
  UserLevelQuery,
} from '@/client/graphql/users.generated';
import { TasksMutationVariables, useTasksMutation } from '@/client/graphql/tasks.generated';
import { withUrql } from '@/config/graphql';
import { PropsWithChildren, useCallback, useEffect, useReducer } from 'react';
import { defaultCurrentUserContext, CurrentUserContext } from './current-user.context';
import { CurrentUser, ActionTypes, SetUserDataAction } from './current-user.types';
import { getTotalXp } from './current-user.helpers';
import { NextUrqlPageContext } from 'next-urql';
import { useToasts } from '@/utils/hooks/useToasts';
import useTranslation from 'next-translate/useTranslation';

const reducer = (state: CurrentUser, { type, payload }: SetUserDataAction) => {
  switch (type) {
    case ActionTypes.SET_USER_DATA:
      return { ...state, ...payload };
  }
};

const getRankByUserLevel = ({
  level,
  min,
  max,
}: UserLevelQuery['currentUserLevelData']): CurrentUser['rank'] => ({
  level,
  minXp: min,
  maxXp: max,
});

const CurrentUserProvider = ({ children, urqlClient }: PropsWithChildren & NextUrqlPageContext) => {
  const [{ data: userData, fetching: fetchingUserData }] = useCurrentUserQuery();
  const [{ data: scholarshipData, fetching: fetchingScholarshipData }] = useUserScholarshipsQuery({
    variables: {
      filter: {
        status: [ScholarshipStatus.Applied, ScholarshipStatus.Approved],
      },
    },
  });
  const [, mutate] = useTasksMutation();
  const [state, dispatch] = useReducer(reducer, defaultCurrentUserContext);
  const { currentUser } = userData || {};
  const { addToast } = useToasts();
  const { t } = useTranslation('common');

  const updateTasks = useCallback(async () => {
    if (!currentUser?.requiredFieldsComplete) return;

    const dailyFilters: TasksMutationVariables = {
      filters: {
        rules: {
          timePeriod: TaskRuleTimePeriod.Daily,
          action: TaskRuleAction.Visit,
        },
      },
    };

    const oneTimeFilters: TasksMutationVariables = {
      filters: {
        rules: {
          timePeriod: TaskRuleTimePeriod.OneTime,
          action: TaskRuleAction.Register,
        },
      },
    };

    const { data: dailyTasks } = await mutate(dailyFilters);
    const { data: oneTimeTasks } = await mutate(oneTimeFilters);

    const dailyXp = getTotalXp(dailyTasks?.performTask || []);
    const oneTimeXp = getTotalXp(oneTimeTasks?.performTask || []);
    const totalTasksXp = dailyXp + oneTimeXp;
    const totalXp = currentUser.xp + totalTasksXp;

    if (dailyXp > 0) {
      addToast(`${t('toasts.xp.daily', { amount: dailyXp })}`);
    }

    if (oneTimeXp > 0) {
      addToast(`${t('toasts.xp.registration', { amount: oneTimeXp })}`);
    }

    const { data } = await urqlClient.query(UserLevelDocument, { xp: totalXp }).toPromise();
    const rank = getRankByUserLevel(data.currentUserLevelData);

    if (rank.level > currentUser.level) {
      addToast(t('toasts.xp.level-up'));
    }

    dispatch({
      type: ActionTypes.SET_USER_DATA,
      payload: {
        xp: totalXp,
        addedXp: totalTasksXp,
        level: rank.level,
        rank,
      },
    });
  }, [currentUser, mutate, urqlClient, addToast, t]);

  useEffect(() => {
    if (fetchingScholarshipData) return;

    const { userScholarships } = scholarshipData || {};

    dispatch({ type: ActionTypes.SET_USER_DATA, payload: { scholarships: userScholarships } });
  }, [fetchingScholarshipData, scholarshipData]);

  useEffect(() => {
    if (fetchingUserData || !currentUser) return;

    updateTasks();
  }, [mutate, fetchingUserData, updateTasks, currentUser]);

  useEffect(() => {
    if (fetchingUserData) return;

    const getLocalUser = localStorage.getItem('user');

    dispatch({
      type: ActionTypes.SET_USER_DATA,
      payload: {
        ...(currentUser || {}),
        isLoggedIn: !!currentUser?.id,
        userLoggedInBefore: !!getLocalUser,
        fetchingUserData,
      },
    });

    if (currentUser && !getLocalUser) {
      localStorage.setItem('user', 'true');
    }
  }, [currentUser, fetchingUserData]);

  return (
    <CurrentUserContext.Provider value={{ ...state, updateState: dispatch }}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export default withUrql(CurrentUserProvider);
