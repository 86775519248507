import { REDIRECT_PATHS } from '@/config/redirect-paths';
import { BACKEND_URLS, FRONTEND_URL, PageUrls, SUPPORT_URL, URLS } from '@/config/urls';
import { GtmEvent, GtmEventCategory, pushDataLayer } from '@/utils/gtm';
import {
  Box,
  Button,
  Container,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Progress,
  Spacer,
  Stack,
  Text,
  chakra,
  useDisclosure,
  useTheme,
  Flex,
} from '@chakra-ui/react';
import { Icon, IconName, Logo, ProfileUserAvatar } from '@meritcircle/ui/components';

import { useRedirect } from '@/utils/hooks';
import { useCurrentUser } from '@/utils/hooks/useCurrentUser';
import { isExternalUrl } from '@meritcircle/ui';
import useTranslation from 'next-translate/useTranslation';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { SearchBar } from './components/search-bar';
import { ProfileDescriptionProps } from './header.types';
import { rem } from 'polished';

type NavItemsSeparator = 'NavItemsSeparator';

type NavItemsWithSeparator = (NavItem | NavItemsSeparator | NavItemSoon)[];
type NavItem = {
  href: string;
  onClick?: () => void;
  title: string;
  soon?: true;
};

type NavItemSoon = {
  title: string;
  soon: true;
};

enum HeaderVariants {
  TRANSPARENT = 'transparent',
  OPAQUE = 'opaque',
}

const NAV_ITEMS_SEPARATOR: NavItemsSeparator = 'NavItemsSeparator';

export const Header = () => {
  const currentUser = useCurrentUser();
  const { t } = useTranslation('common');
  const isLoggedIn = !!currentUser?.id;
  const {
    isOpen: isMobileMenuOpen,
    onOpen: onOpenMobileMenu,
    onClose: onCloseMobileMenu,
  } = useDisclosure();
  const mobileMenuButtonRef = useRef();
  const router = useRouter();
  const { replace, asPath } = router;
  const { gradients } = useTheme();
  const { redirect } = useRedirect();
  const [headerVariant, setHeaderVariant] = useState(HeaderVariants.TRANSPARENT);
  const { isOpen: isSearchOpen, onOpen: openSearch, onClose: closeSearch } = useDisclosure();

  /**
   * Redirect the user to the onboarding if they have not completed it yet. This will be performed
   * for all common platform pages that use this component
   */
  useEffect(() => {
    if (!!currentUser.id && !currentUser.requiredFieldsComplete) {
      replace(REDIRECT_PATHS.ONBOARDING);
    }
  }, [currentUser, replace]);

  const isActive = (href: string): boolean => {
    const relativeHref = href.split(FRONTEND_URL)[1];
    return relativeHref === PageUrls.DASHBOARD
      ? asPath === relativeHref
      : asPath.startsWith(relativeHref);
  };
  const { profilePictureUrl, username, xp, rank } = currentUser;

  const NAV_ITEMS: NavItemsWithSeparator = [
    {
      href: URLS.DASHBOARD,
      title: t('navigation.home'),
    },
    {
      href: URLS.GAMES,
      title: t('navigation.games'),
    },
    {
      href: URLS.ACADEMY,
      title: t('navigation.academy'),
    },
    {
      href: URLS.REWARDS,
      title: t('navigation.rewards'),
    },
  ];

  const MOBILE_NAV_ITEMS = [...NAV_ITEMS];

  const USER_DROPDOWN_NAV_ITEMS: NavItem[] = [
    {
      title: t('navigation.profile'),
      href: URLS.PROFILE,
    },
    {
      href: SUPPORT_URL,
      title: t('navigation.support'),
    },
    {
      title: t('actions.logout'),
      href: `${BACKEND_URLS.LOGOUT}?last_visited=${asPath}`,
    },
  ];
  const GUEST_DROPDOWN_NAV_ITEMS: NavItem[] = [
    {
      title: t('navigation.login'),
      href: `${BACKEND_URLS.LOGIN}?last_visited=${asPath}`,
    },
    {
      title: t('navigation.register'),
      href: `${BACKEND_URLS.REGISTER}?last_visited=${asPath}`,
    },
    {
      href: SUPPORT_URL,
      title: t('navigation.support'),
    },
  ];
  const DROPDOWN_NAV_ITEMS = !isLoggedIn ? GUEST_DROPDOWN_NAV_ITEMS : USER_DROPDOWN_NAV_ITEMS;
  if (!isLoggedIn) {
    MOBILE_NAV_ITEMS.push(...GUEST_DROPDOWN_NAV_ITEMS);
  } else {
    MOBILE_NAV_ITEMS.push(
      {
        href: URLS.PROFILE,
        title: t('navigation.profile'),
      },
      {
        href: SUPPORT_URL,
        title: t('navigation.support'),
      },
    );
  }

  const soonLabel = {
    content: `"${t('navigation.soon')}"`,
    display: 'inline-block',
    mt: '-20px',
    right: '24px',
    mr: '-24px',
    position: 'relative',
    color: 'lightPurple',
    borderRadius: 'sm',
    fontSize: '10px',
    background: gradients.orangeStroke,
    backgroundClip: 'text',
  };

  useEffect(() => {
    setHeaderVariant(window.scrollY > 0 ? HeaderVariants.OPAQUE : HeaderVariants.TRANSPARENT);

    const handleWindowScroll = () => {
      setHeaderVariant(window.scrollY > 0 ? HeaderVariants.OPAQUE : HeaderVariants.TRANSPARENT);
    };

    window.addEventListener('scroll', handleWindowScroll);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, []);

  return (
    <>
      <Box
        as="header"
        position="sticky"
        top={0}
        w="100%"
        color="white"
        bg={headerVariant === HeaderVariants.OPAQUE ? 'black' : 'transparent'}
        borderBottom="1px solid"
        borderColor={headerVariant === HeaderVariants.OPAQUE ? 'border' : 'transparent'}
        transition="background 0.2s ease, border-color 0.2s ease"
        zIndex={100}>
        {/* relative position to keep search input dropdown in place */}
        <Box position="relative">
          <Container>
            <Grid>
              <Flex gridArea="1/1" alignItems="stretch" justifyContent="space-between">
                <NextLink href={URLS.DASHBOARD} passHref legacyBehavior>
                  <Link my={6} transition="opacity 0.375s ease" opacity={isMobileMenuOpen ? 0 : 1}>
                    <Logo showRainbow={!isSearchOpen} />
                  </Link>
                </NextLink>
                <chakra.nav alignItems="center" display={{ base: 'none', lg: 'flex' }}>
                  <Stack direction="row" spacing="lg" h="100%" alignItems="center">
                    {NAV_ITEMS.map((item, i) => {
                      if (item === NAV_ITEMS_SEPARATOR) {
                        return <Spacer borderLeft="1px solid" borderColor="border" key={i} />;
                      }

                      const isAnchor = 'href' in item;

                      // If link is not an anchor, it will be a coming soon page and we only render text
                      if (!isAnchor) {
                        return (
                          <Text
                            key={i}
                            as="span"
                            fontSize="sm"
                            px="md"
                            h="10"
                            display="flex"
                            alignItems="center"
                            textDecoration="none"
                            lineHeight={1}
                            color="white"
                            rounded="full"
                            position="relative"
                            _after={{ ...soonLabel }}>
                            {item.title}
                          </Text>
                        );
                      }

                      return (
                        <NextLink href={item.href} passHref key={i} legacyBehavior>
                          <Link
                            cursor="pointer"
                            fontSize="sm"
                            px="md"
                            h="10"
                            display="flex"
                            alignItems="center"
                            textDecoration="none"
                            lineHeight={1}
                            color="white"
                            rounded="full"
                            position="relative"
                            isExternal={isAnchor && isExternalUrl(item.href)}
                            data-testid={`${item.title}-nav-button`}
                            onClick={() => {
                              pushDataLayer({
                                event: GtmEvent.menuUsed,
                                eventCategory: GtmEventCategory.header,
                                eventAction: item.title,
                                eventLabel: undefined,
                              });
                            }}
                            _hover={{
                              background: 'lightGrey',
                              color: 'white',
                            }}
                            {...(item.soon ? { _after: { ...soonLabel } } : {})}
                            {...(isActive(item.href)
                              ? {
                                  bg: 'lightGrey',
                                  color: 'white',
                                }
                              : {})}>
                            {item.title}
                          </Link>
                        </NextLink>
                      );
                    })}
                  </Stack>
                </chakra.nav>

                <chakra.nav
                  display={{ base: 'none', lg: 'flex' }}
                  alignItems="center"
                  justifyContent="flex-end"
                  // same width as logo, to keep nav centered
                  minW={rem(173)}>
                  <Menu placement="bottom-end">
                    <IconButton
                      mr={4}
                      minWidth="auto"
                      aria-label={`${t('actions.open')} ${t('actions.search')}`}
                      onClick={openSearch}
                      variant="link"
                      data-testid="search-nav-button"
                      icon={<Icon icon={IconName.search} />}
                    />
                    {isLoggedIn ? (
                      <MenuButton
                        data-testid="sign-in-button"
                        as={Button}
                        textAlign="left"
                        outline="0"
                        fontSize="xs"
                        bg="none"
                        px={0}
                        boxShadow="none"
                        _hover={{
                          bg: 'none',
                        }}
                        leftIcon={
                          isLoggedIn && (
                            <ProfileUserAvatar
                              size="md"
                              username={username}
                              avatarSrc={profilePictureUrl}
                              hideUsername
                            />
                          )
                        }>
                        {rank && (
                          <ProfileDescription
                            username={username}
                            label={`${t('labels.lvl')} ${rank.level}`}
                            level={{
                              min: rank.minXp,
                              max: rank.maxXp,
                              value: xp,
                            }}
                          />
                        )}
                      </MenuButton>
                    ) : (
                      <Button
                        as={MenuButton}
                        variant="secondary"
                        fontSize={{ base: 'xs', lg: 'sm' }}
                        p="0.875rem 1rem"
                        data-testid="sign-in-button"
                        _hover={{ border: 'none', color: 'lightPurple', background: 'lightGrey' }}>
                        {t('navigation.account')}
                      </Button>
                    )}

                    <MenuList
                      mt="-2"
                      w="20rem"
                      bg="gray.900"
                      color="white"
                      borderRadius="lg"
                      border="1"
                      boxShadow="dropdown"
                      borderColor="border">
                      <MenuItem
                        as="span"
                        px="lg"
                        py="sm"
                        color="white"
                        fontWeight={400}
                        _focus={{ bg: 'transparent' }}
                        _hover={{ bg: 'transparent', color: 'white' }}>
                        <ProfileUserAvatar
                          size="md"
                          username={username}
                          avatarSrc={profilePictureUrl}
                        />
                      </MenuItem>
                      <MenuDivider />
                      {DROPDOWN_NAV_ITEMS.map((item, i) => (
                        <NextLink href={item.href} passHref key={i} legacyBehavior>
                          <MenuItem
                            as={Link}
                            _focus={{ bg: 'transparent' }}
                            _hover={{ bg: 'transparent', color: 'white' }}
                            onClick={() => {
                              pushDataLayer({
                                event: GtmEvent.menuUsed,
                                eventCategory: GtmEventCategory.header,
                                eventAction: 'account',
                                eventLabel: item.title,
                              });
                              item.onClick?.();
                            }}
                            px="lg"
                            py="sm"
                            color="muted"
                            isExternal={isExternalUrl(item.href)}>
                            {item.title}
                          </MenuItem>
                        </NextLink>
                      ))}
                    </MenuList>
                  </Menu>
                </chakra.nav>

                <Stack
                  ml="auto"
                  direction="row"
                  display={{ base: 'flex', lg: 'none' }}
                  spacing="space.12"
                  alignItems="center">
                  <ProfileUserAvatar
                    size="sm"
                    username={username}
                    hideUsername
                    avatarSrc={profilePictureUrl}
                  />
                  <Box w="0.0625rem" h="1.875rem" bg="transparent" />
                  <IconButton
                    aria-label={`${t('actions.open')} ${t('actions.search')}`}
                    onClick={openSearch}
                    variant="link"
                    minWidth="auto"
                    icon={<Icon icon={IconName.search} />}
                  />
                  <Box w="0.0625rem" h="1.875rem" bg="transparent" />
                  <IconButton
                    color="gray.700"
                    bg="white"
                    size="md"
                    height="2.5rem"
                    aria-label="Open mobile menu"
                    isRound
                    icon={<Icon icon={IconName.menu} />}
                    ref={mobileMenuButtonRef}
                    onClick={onOpenMobileMenu}
                  />
                </Stack>
              </Flex>
              <SearchBar isOpen={isSearchOpen} onClose={closeSearch} />
            </Grid>
          </Container>
          {isSearchOpen && (
            <Box position="absolute" w="100%" h="100vh" backdropFilter="blur(6px)" />
          )}
        </Box>
      </Box>

      <Drawer
        isOpen={isMobileMenuOpen}
        size="full"
        isFullHeight
        onClose={onCloseMobileMenu}
        finalFocusRef={mobileMenuButtonRef}>
        <DrawerOverlay />
        <DrawerContent bg="black" alignItems="center">
          <DrawerHeader
            display="flex"
            w="100%"
            px="md"
            py="lg"
            borderBottom="1px solid"
            borderColor="border"
            alignItems="center"
            justifyContent="space-between">
            <NextLink href="/" passHref legacyBehavior>
              <Link tabIndex={-1}>
                <Logo />
              </Link>
            </NextLink>
            <IconButton
              color="gray.700"
              bg="white"
              size="md"
              ml="lg"
              height="2.5rem"
              aria-label="Close mobile menu"
              isRound
              icon={<Icon icon={IconName.close} />}
              ref={mobileMenuButtonRef}
              onClick={onCloseMobileMenu}
            />
          </DrawerHeader>
          <DrawerBody w="100%" px={0}>
            <Stack direction="column" spacing="lg" mt="md">
              {MOBILE_NAV_ITEMS.map((item, i) => {
                if (item === NAV_ITEMS_SEPARATOR) {
                  return <Spacer borderTop="1px solid" borderColor="border" key={i} />;
                }

                const isAnchor = 'href' in item;

                if (!isAnchor) {
                  return (
                    <Text
                      key={i}
                      as="span"
                      position="relative"
                      px="md"
                      h="9"
                      display="flex"
                      alignItems="center"
                      textDecoration="none"
                      color="muted"
                      _after={{ ...soonLabel }}>
                      {item.title}
                    </Text>
                  );
                }

                return (
                  <NextLink href={item.href} passHref key={i} legacyBehavior>
                    <Link
                      isExternal={isExternalUrl(item.href)}
                      position="relative"
                      px="md"
                      h="9"
                      display="flex"
                      alignItems="center"
                      textDecoration="none"
                      color="muted"
                      onClick={() => {
                        pushDataLayer({
                          event: GtmEvent.menuUsed,
                          eventCategory: GtmEventCategory.header,
                          eventAction: 'account',
                          eventLabel: item.title,
                        });
                      }}
                      _hover={{
                        bg: 'none',
                        color: 'white',
                      }}>
                      {item.title}
                    </Link>
                  </NextLink>
                );
              })}
            </Stack>
          </DrawerBody>
          <DrawerFooter w="100%" px="md" pb="lg">
            {isLoggedIn && (
              <Button
                as={Link}
                size="md"
                variant="outline"
                href={BACKEND_URLS.LOGOUT}
                onClick={() => redirect(`${BACKEND_URLS.LOGOUT}?last_visited=${asPath}`, asPath)}
                isExternal={isExternalUrl(BACKEND_URLS.LOGOUT)}
                width="full">
                {t('actions.logout')}
              </Button>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const ProfileDescription = ({ username, label, level }: ProfileDescriptionProps) => (
  <Box>
    <Text size="sm" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
      {username}
    </Text>
    <Text size="xs" mb={0.5}>
      {label}
    </Text>
    <Progress min={level.min} max={level.max} value={level.value} />
  </Box>
);
