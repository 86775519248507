import * as Types from './types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BaseUserFieldsFragment = { __typename: 'User', id: string, username?: string | null, firstName?: string | null, lastName?: string | null, discordId?: string | null, twitterId?: string | null, profilePictureUrl?: string | null, emailAddress: string, phoneNumber?: string | null, dateOfBirth?: string | null, timeZone?: string | null, createdAt?: any | null, requiredFieldsComplete?: boolean | null, xp: number, level: number };

export type CurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename: 'Query', currentUser: { __typename: 'User', id: string, username?: string | null, firstName?: string | null, lastName?: string | null, discordId?: string | null, twitterId?: string | null, profilePictureUrl?: string | null, emailAddress: string, phoneNumber?: string | null, dateOfBirth?: string | null, timeZone?: string | null, createdAt?: any | null, requiredFieldsComplete?: boolean | null, xp: number, level: number } };

export type UserLevelQueryVariables = Types.Exact<{
  xp: Types.Scalars['Float'];
}>;


export type UserLevelQuery = { __typename: 'Query', currentUserLevelData: { __typename: 'Level', level: number, min: number, max?: number | null } };

export type UpdateCurrentUserMutationVariables = Types.Exact<{
  input: Types.UpdateUserInput;
}>;


export type UpdateCurrentUserMutation = { __typename: 'Mutation', updateCurrentUser: { __typename: 'User', id: string, username?: string | null, firstName?: string | null, lastName?: string | null, discordId?: string | null, twitterId?: string | null, profilePictureUrl?: string | null, emailAddress: string, phoneNumber?: string | null, dateOfBirth?: string | null, timeZone?: string | null, createdAt?: any | null, requiredFieldsComplete?: boolean | null, xp: number, level: number } };

export type UpdatePasswordMutationVariables = Types.Exact<{
  currentPassword: Types.Scalars['String'];
  newPassword: Types.Scalars['String'];
}>;


export type UpdatePasswordMutation = { __typename: 'Mutation', updatePassword: { __typename: 'User', id: string, username?: string | null, firstName?: string | null, lastName?: string | null, discordId?: string | null, twitterId?: string | null, profilePictureUrl?: string | null, emailAddress: string, phoneNumber?: string | null, dateOfBirth?: string | null, timeZone?: string | null, createdAt?: any | null, requiredFieldsComplete?: boolean | null, xp: number, level: number } };

export type UpdateTwitterHandleMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type UpdateTwitterHandleMutation = { __typename: 'Mutation', updateTwitterHandle: { __typename: 'SocialNameUpdateResult', success: boolean } };

export type UpdateDiscordUsernameMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type UpdateDiscordUsernameMutation = { __typename: 'Mutation', updateDiscordUsername: { __typename: 'SocialNameUpdateResult', success: boolean } };

export const BaseUserFieldsFragmentDoc = gql`
    fragment BaseUserFields on User {
  id
  username
  firstName
  lastName
  discordId
  twitterId
  profilePictureUrl
  emailAddress
  phoneNumber
  dateOfBirth
  timeZone
  createdAt
  requiredFieldsComplete
  xp
  level
}
    `;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    ...BaseUserFields
  }
}
    ${BaseUserFieldsFragmentDoc}`;

export function useCurrentUserQuery(options?: Omit<Urql.UseQueryArgs<CurrentUserQueryVariables>, 'query'>) {
  return Urql.useQuery<CurrentUserQuery>({ query: CurrentUserDocument, ...options });
};
export const UserLevelDocument = gql`
    query UserLevel($xp: Float!) {
  currentUserLevelData(xp: $xp) {
    level
    min
    max
  }
}
    `;

export function useUserLevelQuery(options: Omit<Urql.UseQueryArgs<UserLevelQueryVariables>, 'query'>) {
  return Urql.useQuery<UserLevelQuery>({ query: UserLevelDocument, ...options });
};
export const UpdateCurrentUserDocument = gql`
    mutation UpdateCurrentUser($input: UpdateUserInput!) {
  updateCurrentUser(input: $input) {
    ...BaseUserFields
  }
}
    ${BaseUserFieldsFragmentDoc}`;

export function useUpdateCurrentUserMutation() {
  return Urql.useMutation<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>(UpdateCurrentUserDocument);
};
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($currentPassword: String!, $newPassword: String!) {
  updatePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
    ...BaseUserFields
  }
}
    ${BaseUserFieldsFragmentDoc}`;

export function useUpdatePasswordMutation() {
  return Urql.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument);
};
export const UpdateTwitterHandleDocument = gql`
    mutation UpdateTwitterHandle {
  updateTwitterHandle {
    success
  }
}
    `;

export function useUpdateTwitterHandleMutation() {
  return Urql.useMutation<UpdateTwitterHandleMutation, UpdateTwitterHandleMutationVariables>(UpdateTwitterHandleDocument);
};
export const UpdateDiscordUsernameDocument = gql`
    mutation updateDiscordUsername {
  updateDiscordUsername {
    success
  }
}
    `;

export function useUpdateDiscordUsernameMutation() {
  return Urql.useMutation<UpdateDiscordUsernameMutation, UpdateDiscordUsernameMutationVariables>(UpdateDiscordUsernameDocument);
};