import { Container, Heading, VStack, Flex, Button, Link } from '@chakra-ui/react';
import { TextualHeroProps } from './textual-hero.types';
import NextLink from 'next/link';
import { RichText } from '../rich-text/rich-text';
import { Options } from '@contentful/rich-text-react-renderer';
import { ReactNode } from 'react';
import { BLOCKS, MARKS, Document } from '@contentful/rich-text-types';
import { Node } from '@meritcircle/ui/components';
import { isExternalUrl } from '../../utils';

const defaultSubtitleOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: Node, children: ReactNode) => (
      <Heading size="h4" variant="glowing" lineHeight={'1.4'} color="white">
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_6]: (node: Node, children: ReactNode) => (
      <Heading as="p" size="xs" variant="glowing" lineHeight={'1.4'} color="white">
        {children}
      </Heading>
    ),
  },
  renderMark: {
    [MARKS.BOLD]: (text: ReactNode) => (
      <Heading as="span" size="h4" variant="glowing" color="lightPurple">
        {text}
      </Heading>
    ),
  },
};

const isOfTypeDocument = (subtitle: TextualHeroProps['subtitle']): subtitle is Document =>
  (subtitle as Document).content !== undefined;

const Subtitle = ({
  subtitle,
  subtitleOptions,
}: Pick<TextualHeroProps, 'subtitle' | 'subtitleOptions'>) => {
  if (!subtitle) return null;

  if (isOfTypeDocument(subtitle)) {
    return <RichText document={subtitle} options={subtitleOptions || defaultSubtitleOptions} />;
  }

  return (
    <Heading as="p" size="xs" color="white">
      {subtitle}
    </Heading>
  );
};

export const TextualHero = ({
  title,
  subtitle,
  subtitleOptions,
  cta,
  ...rest
}: TextualHeroProps) => (
  <Container
    as="section"
    display="flex"
    justifyContent="center"
    py={{ base: 'space.32', md: 'space.48' }}
    {...rest}>
    <VStack spacing={{ base: 4, md: 6 }} alignItems="center">
      <Heading as="h1" size="2xl" variant="glowing" textAlign="center">
        {title}
      </Heading>
      <Flex
        gap="space.16"
        flexDirection="column"
        alignItems="center"
        maxWidth={540}
        textAlign="center">
        <Subtitle subtitle={subtitle} subtitleOptions={subtitleOptions} />
        {cta && (
          <NextLink href={cta.url} passHref legacyBehavior>
            <Button
              as={Link}
              variant="primary"
              onClick={cta?.onClick}
              isExternal={isExternalUrl(cta.url)}>
              {cta.label}
            </Button>
          </NextLink>
        )}
      </Flex>
    </VStack>
  </Container>
);
