import { Badge, Box, Flex, Grid, Heading, Link, Text } from '@chakra-ui/react';
import NextLink from 'next/link';
import { getPlaceholderUrl, isExternalUrl } from '../../utils';
import { LazyImage } from '../lazy-image/lazy-image';
import { TileProps } from './tile.types';
import { rem } from 'polished';

export const Tile = ({
  title,
  image,
  label,
  subtitle,
  href,
  tags,
  badges,
  status,
  onClick,
  h,
  titleSize = 'base',
  endAt,
  ...props
}: TileProps) => {
  const titleFontSizes = {
    sm: {
      base: 'md',
      md: 'md',
      lg: 'lg',
    },
    base: {
      base: 'md',
      md: 'xl',
      lg: 'xl',
    },
  };

  return (
    <NextLink href={href} passHref legacyBehavior>
      <Grid
        onClick={onClick}
        as={Link}
        isExternal={isExternalUrl(href)}
        textDecoration="none"
        position="relative"
        borderRadius="base"
        overflow="hidden"
        h="100%"
        _hover={{ textDecoration: 'none' }}
        _after={{
          content: '" "',
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%',
          background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7))',
        }}
        {...props}>
        <LazyImage
          gridArea="1/1"
          alt={image.alt || `${title} image`}
          src={image.src}
          placeholderUrl={getPlaceholderUrl(image.src)}
          objectFit="cover"
          w="100%"
          loading="lazy"
          h={h}
        />
        <Flex gridArea="1/1" p={{ base: 4, md: 6 }} flexDirection="column">
          {!!badges?.length && (
            <Flex justifyContent="space-between">
              <Flex gap={1} flexWrap="wrap">
                {badges.map((badge, index) => (
                  <Badge {...badge} key={index} />
                ))}
              </Flex>
              <Box></Box>
            </Flex>
          )}
          <Flex flexDirection="column" mt="auto" zIndex={1} gap={2}>
            {label && (
              <Text color="desiredDawn" size="xs" textTransform="uppercase">
                {label}
              </Text>
            )}
            {status && (
              <Text
                fontSize={{ base: '0.625rem', md: '0.75rem' }}
                fontWeight="700"
                color="lightPurple"
                textTransform="uppercase">
                {status}
              </Text>
            )}
            <Heading
              as="h4"
              wordBreak="break-word"
              data-testid="grid-game-tile-heading"
              size="h3"
              lineHeight="1.1"
              fontSize={titleFontSizes[titleSize]}>
              {title}
            </Heading>
            {!!tags?.length && (
              <Flex gap={2} flexWrap="wrap">
                {tags.map((tag, index) => (
                  <Badge {...tag} key={index} data-testid="grid-game-tile-badge" />
                ))}
              </Flex>
            )}
            {subtitle && (
              <Text size={{ base: 'xs', md: 'sm' }} color="lightPurple" mt={{ base: -1, md: 2 }}>
                {subtitle}
              </Text>
            )}
          </Flex>
        </Flex>
      </Grid>
    </NextLink>
  );
};
