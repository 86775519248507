import { useLazyImageSrc } from '@meritcircle/ui/utils/hooks/use-lazy-image';
import { Heading, LinkBox, LinkOverlay, Text } from '@chakra-ui/react';
import NextLink from 'next/link';
import { ArticleCardProps } from './article-card.types';

export const ArticleCard = ({ href, title, text, background }: ArticleCardProps) => {
  const [ref, backgroundUrl] = useLazyImageSrc({
    url: background,
    placeholderUrl: `${background}?w=30`,
  });

  return (
    <LinkBox
      ref={ref}
      rounded="lg"
      bg="gray.800"
      p="xl"
      minH={['16.375rem', null, '15.625rem']}
      {...(backgroundUrl
        ? {
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${backgroundUrl}) no-repeat 50%`,
            backgroundSize: 'cover',
          }
        : {})}
      _hover={{
        p: {
          color: 'white',
        },
      }}>
      <NextLink href={href} passHref legacyBehavior>
        <Heading size="h4" as={LinkOverlay}>
          {title}
        </Heading>
      </NextLink>
      <Text variant="muted" mt="sm" transition="color .2s ease">
        {text}
      </Text>
    </LinkBox>
  );
};
