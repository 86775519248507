/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import { CurrentUser } from './current-user.types';

export const defaultCurrentUserContext: CurrentUser = {
  id: undefined,
  emailAddress: undefined,
  xp: 0,
  level: 1,
  rank: null,
  isLoggedIn: false,
  userLoggedInBefore: false,
  fetchingUserData: true,
  updateState: () => {},
};

export const CurrentUserContext = createContext<CurrentUser>(defaultCurrentUserContext);
