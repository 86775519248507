import { Avatar, Stack, StackProps, Text, TypographyProps, useTheme, Box } from '@chakra-ui/react';
import { ProfileUserAvatarProps } from './profile-user-avatar.types';
import userAvatarImage from '../../../assets/images/user-placeholder-200.png';

export const ProfileUserAvatar = ({
  username,
  avatarSrc,
  hideUsername,
  size = 'lg',
  ...stackProps
}: ProfileUserAvatarProps) => {
  const { gradients } = useTheme();

  const stackSpacingMap: {
    [key: string]: StackProps['spacing'];
  } = {
    sm: 'lg',
    md: 'lg',
    lg: 'xl',
  };

  const textFontSizeMap: {
    [key: string]: TypographyProps['fontSize'];
  } = {
    sm: 'sm',
    md: 'md',
    lg: ['2xl', null, '3xl'],
  };

  return (
    <Stack
      alignItems="center"
      direction="row"
      spacing={stackSpacingMap[size]}

      {...stackProps}>
      <Avatar
        background={gradients.lightPurple}
        size={size}
        name={username}
        src={avatarSrc || userAvatarImage.src}
      />
      {!hideUsername && (
        <Box
          // Setting min-width is required to make ellipsis work
          // See https://css-tricks.com/flexbox-truncated-text/
          minW={0}>
          <Text
            color="white"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            fontSize={textFontSizeMap[size]}
            fontWeight={400}>
            {username}
          </Text>
        </Box>
      )}
    </Stack>
  );
};
